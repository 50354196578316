import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import socialIcon from "../components/socialicon"
import Fade from "react-reveal/Fade"

const IndexPage = () => (
  <div>
    <SEO title="Home" />
    <div
      style={{
        background: "#fff",
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        fontFamily: "Roboto Mono",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div style={{ alignSelf: "center", justifySelf: "center" }}>
        under construction
      </div>
    </div>
  </div>
)

export default IndexPage
